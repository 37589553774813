/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/card";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tag,
  useToast,
} from "@chakra-ui/react";
import {
  getSubscriptionPlans,
  invoiceDownloadApi,
  transactionListApi,
  upgradeCurrentPlans,
  upgradeSubscriptionPlans,
  getActivePlanDetil,
  cancelCurrentPlan,
  downgradeCurrentPlans,
  getTransactionLogs,
} from "services/pricing";
import Loader from "components/Loader/Loader";
import TooltipHorizon from "components/tooltip";
import Pagination from "components/pagination";
import { MdOutlineHistory } from "react-icons/md";
import { profileDetail } from "services/Auth";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
const Price = () => {
  const initialRef = useRef(null);
  // const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [date, setDate] = useState<any>(0);
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [downloadType, setDownloadType] = useState("PDF");
  const [loading, setLoading] = useState(true);
  const [transactionData, setTransactionData] = useState([]);
  const [plansData, setPlansData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [amount, setAmount] = useState("");
  const [packageId, setPackageId] = useState("");
  const [isUpgradeConfirmModal, setIsUpgradeConfirmModal] = useState(false);
  const [planAction, setPlanAction] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [activePlanData, setActivePlanData] = useState(null);
  const [isYearly, setIsYearly] = useState(true);
  const [isCancelConfirmModal, setIsCancelConfirmModal] = useState(false);
  const [isUpgradeDowngradeConfirmModal, setIsUpgradeDowngradeConfirmModal] =
    useState(false);

  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTransaction = transactionData?.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const handleSortByDate = () => {
    let direction = "asc";
    if (sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: "created_at", direction });

    const sortedData = [...transactionData].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (dateA < dateB) {
        return direction === "asc" ? -1 : 1;
      }
      if (dateA > dateB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setTransactionData(sortedData);
  };
  // const handleDropdownToggle = (dropdownName: string) => {
  //   setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  // };
  const handleDownload = async () => {
    setLoading(true);
    let monthNumber = Number(date) + 1;
    invoiceDownloadApi(monthNumber)
      .then((response) => {
        if (response?.success) {
          if (response?.path) {
            window.open(response.path, "_blank");
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };

  const handleAddAmount = () => {
    navigate("/admin/plans");
  };
  // const amount: any = localStorage.getItem("amount");

  useEffect(() => {
    setLoading(true);
    transactionListApi({ token: "" })
      .then((response) => {
        if (response?.success) {
          const hasSuccessStatus = response?.data.some(
            (obj: any) => obj.status === "success"
          );
          localStorage.setItem("isTransaction", hasSuccessStatus);
          const sortedData = response?.data.sort((a: any, b: any) => {
            const dateA: any = new Date(a.created_at);
            const dateB: any = new Date(b.created_at);
            return dateB - dateA;
          });

          setTransactionData(sortedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
    fetchUserProfile();
    fetchPlans();
    fetchActivePlan();
  }, []);
  const fetchUserProfile = () => {
    profileDetail()
      .then((response) => {
        if (response?.success) {
          setAmount(response?.data?.[0]?.amount);
          setPackageId(response?.data?.[0]?.package_id);
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };
  const fetchPlans = () => {
    getSubscriptionPlans()
      .then((response) => {
        if (response?.success) {
          if (response?.data) {
            const sortedPlans = [...response?.data].sort(
              (a, b) =>
                Number(a.monthly_subscription_fee) -
                Number(b.monthly_subscription_fee)
            );
            setPlansData(sortedPlans);
          }
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };
  const fetchActivePlan = () => {
    getActivePlanDetil()
      .then((response) => {
        if (response?.success) {
          if (response?.data?.length === 0) {
            setIsYearly(false);
          }
          if (response?.data) {
            setActivePlanData(response?.data[0]);
          }
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };
  const monthNames = [
    t("billing.months.January"),
    t("billing.months.February"),
    t("billing.months.March"),
    t("billing.months.April"),
    t("billing.months.May"),
    t("billing.months.June"),
    t("billing.months.July"),
    t("billing.months.August"),
    t("billing.months.September"),
    t("billing.months.October"),
    t("billing.months.November"),
    t("billing.months.December"),
  ];
  const togglePlanType = () => {
    setIsYearly((prev) => !prev); // Toggle between monthly and yearly
  };
  const subscribePlan = () => {
    let payload = {
      subscription_price_id: selectedPlan?.subscription_price_id,
      monthly_subscription_fee: selectedPlan?.monthly_subscription_fee,
      package_id: selectedPlan?.package_id,
      interval: selectedPlan?.interval,
      yearly_subscription_fee: selectedPlan?.yearly_subscription_fee,
    };
    setLoading(true);
    upgradeSubscriptionPlans(payload)
      .then((response) => {
        if (response?.success) {
          window.location.href = response?.redirectUrl;
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  // const activePlan = plansData.find((plan) => {
  //   if (plan.package_id === packageId) {
  //     if (plan.interval === "month") {
  //       setIsYearly(false);
  //     } else {
  //       setIsYearly(true);
  //     }
  //     return plan.package_id === packageId;
  //   }
  // });
  const activePlan = plansData.find((plan) => plan.package_id === packageId);

  // Check if a plan was found and then update the state
  useEffect(() => {
    if (activePlan) {
      if (activePlan.interval === "month") {
        setIsYearly(false);
      } else {
        setIsYearly(true);
      }
    }
  }, [activePlan]);

  const upgradePlan = () => {
    let payload = {
      // customer_id: activePlanData?.customer_id,
      new_Price_id: selectedPlan?.subscription_price_id,
      subscription_id: activePlanData?.subscription_id,
      // package_id: selectedPlan?.package_id,
      // old_price_id: activePlanData?.old_price_id,
    };
    setLoading(true);
    upgradeCurrentPlans(payload)
      .then((response) => {
        if (response?.success) {
          // window.location.href = response?.url;
          toast({
            description: t("messages.planUpgradeMsg"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setIsUpgradeDowngradeConfirmModal(false);
          fetchPlans();
          fetchActivePlan();
          fetchUserProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const downgradePlan = () => {
    let payload = {
      new_Price_id: selectedPlan?.subscription_price_id,
      subscription_id: activePlanData?.subscription_id,
    };
    setLoading(true);
    downgradeCurrentPlans(payload)
      .then((response) => {
        if (response?.success) {
          // window.location.href = response?.url;
          toast({
            description: t("messages.planUpgradeMsg"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setIsUpgradeDowngradeConfirmModal(false);
          fetchPlans();
          fetchActivePlan();
          fetchUserProfile();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const cancelPlan = () => {
    setLoading(true);
    cancelCurrentPlan(activePlanData?.subscription_id)
      .then((response) => {
        if (response?.success) {
          fetchPlans();
          fetchActivePlan();
          setIsCancelConfirmModal(false);
          toast({
            description: t("messages.cancelPlanMsg"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const openUpgradeModal = (item: any, status: any) => {
    setPlanAction(status);
    setSelectedPlan(item);
    if (status === "subscribe") {
      setIsUpgradeConfirmModal(true);
    } else {
      setIsUpgradeDowngradeConfirmModal(true);
    }
  };
  const handleUpgradeClose = () => {
    setIsUpgradeConfirmModal(false);
  };
  const submitUpgradePlan = () => {
    if (planAction === "subscribe") {
      subscribePlan();
    }
    if (planAction === "upgrade") {
      upgradePlan();
    }
    if (planAction === "downgrade") {
      downgradePlan();
    }
  };
  const handleCancelPlanClose = () => {
    setIsCancelConfirmModal(false);
  };
  const handleUpgradeDowngradeClose = () => {
    setIsUpgradeDowngradeConfirmModal(false);
  };
  const transactionLogs = () => {
    let payload = {
      customer_id: activePlanData?.customer_id,
    };
    setLoading(true);
    getTransactionLogs(payload)
      .then((response) => {
        if (response?.success) {
          window.location.href = response?.url;
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const filteredData = plansData.filter((plan: any) =>
    isYearly ? plan.interval === "year" : plan.interval === "month"
  );
  return (
    <div className="third-step relative flex w-full flex-col gap-5">
      {loading && <Loader />}
      <div className="mt-3 flex h-fit w-full flex-col gap-5 xxl:flex-row">
        <div className="w-full lg:col-span-6 lg:!mb-0">
          <Card extra={"w-full h-full p-[24px] bg-cover"}>
            <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
              {t("billing.billingInformation")}
            </div>
            <div className="mt-12 flex flex-wrap justify-between gap-4">
              <div className="flex flex-col">
                <div className="text-[20px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                  {t("billing.currentBalance")}
                </div>

                <div className="mt-2 text-[26px] font-bold capitalize">
                  {/* ${amount !== "null" ? Number(amount).toFixed(2) : 0.0} */}
                  {amount !== "null" || amount !== null ? (
                    <span>
                      {Number(amount) < 0 ? (
                        <span className="text-[#d45b5b]">
                          ${Number(amount).toFixed(2)}
                        </span>
                      ) : (
                        <span className="text-[#5bd45b]">
                          ${Number(amount).toFixed(2)}
                        </span>
                      )}
                    </span>
                  ) : (
                    <span className="text-[#5bd45b]">$0.0</span>
                  )}
                </div>
                <button
                  onClick={handleAddAmount}
                  className="linear mt-5 w-full rounded bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                >
                  {t("billing.addFund")}
                </button>
              </div>
              <div className="flex flex-col">
                <div className="mb-4 text-[20px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                  {t("billing.invoice")}
                </div>
                <div className="my-4 flex flex-row items-center gap-2 text-[24px] font-medium">
                  <select
                    id="industry_id"
                    name="industry_id"
                    onChange={(e) => setDate(e.target.value)}
                    className="w-full rounded border-[1px] border-gray-200 px-4 py-2 text-[18px] leading-tight  focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  >
                    {monthNames?.map((month: any, index: number) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <span
                    onClick={handleDownload}
                    className="flex cursor-pointer gap-2 rounded border border-blue-700 bg-white px-3 py-1 text-[20px] text-blue-700 dark:bg-navy-900"
                  >
                    {t("billing.download")}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="w-full lg:col-span-6 lg:!mb-0">
          <Card extra={"w-full h-full p-[16px] bg-cover"}>
            <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
              {t("billing.transactionHistory")}
            </div>
            <Box p={4} pt={6}>
              <table className="responsive-table w-full">
                <thead className="border-b-2">
                  <tr>
                    <th scope="col">
                      {" "}
                      <p
                        className="flex cursor-pointer items-center justify-center text-sm font-bold text-gray-600 dark:text-white"
                        onClick={handleSortByDate}
                      >
                        {t("billing.date")}
                        {sortConfig.key === "created_at" && (
                          <span>
                            {sortConfig.direction === "asc" ? (
                              <IoIosArrowRoundUp className="h-6 w-6" />
                            ) : (
                              <IoIosArrowRoundDown className="h-6 w-6" />
                            )}
                          </span>
                        )}
                      </p>
                    </th>
                    <th scope="col">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600  dark:text-white">
                        {t("billing.amount")}
                      </p>
                    </th>
                    <th scope="col">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        {t("billing.transactionId")}
                      </p>
                    </th>
                    <th scope="col">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        {t("billing.status")}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransaction.length ? (
                    currentTransaction.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className="px-2 py-2" data-label="Date">
                          {" "}
                          <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                            {new Date(item.created_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </p>
                        </td>
                        <td className="px-2 py-2" data-label="Amount">
                          {" "}
                          <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                            $ {item?.amount}
                          </p>
                        </td>
                        <td className="p-4" data-label="Transaction id">
                          {" "}
                          <TooltipHorizon
                            trigger={
                              <p className="m-auto max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold text-navy-700 dark:text-white">
                                {item?.transaction_id}
                              </p>
                            }
                            placement="top"
                            content={item?.transaction_id}
                            extra="rounded-full text-[11px]"
                          />
                        </td>
                        <td className="p-4" data-label="Status">
                          {" "}
                          <p className="flex items-center justify-center gap-4 rounded-full font-semibold capitalize text-[#000]">
                            {item?.status === "success" && (
                              <Tag variant="solid" colorScheme="green">
                                {item?.status}
                              </Tag>
                            )}
                            {item?.status === "pending" && (
                              <Tag variant="solid" colorScheme="yellow">
                                {item?.status}
                              </Tag>
                            )}
                            {item?.status === "error" && (
                              <Tag variant="solid" colorScheme="red">
                                {item?.status}
                              </Tag>
                            )}
                            {item?.status === "cancelled" && (
                              <Tag variant="solid">{item?.status}</Tag>
                            )}
                            {!item?.status && <span>-</span>}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-4" colSpan={4}>
                        <div className="col-span-3 mb-auto flex flex-col items-center justify-center">
                          <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                            <MdOutlineHistory />
                          </div>
                          <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                            {t("billing.noTransaction")}
                          </h4>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                perPage={perPage}
                allTotal={transactionData?.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Box>
          </Card>
        </div>
        <Modal
          isCentered
          initialFocusRef={initialRef}
          isOpen={isUpgradeConfirmModal}
          onClose={handleUpgradeClose}
          size={"md"}
          scrollBehavior={"inside"}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent className="dark:bg-navy-900">
            <ModalCloseButton />
            <ModalBody pb={6}>
              <div className="mt-6 flex flex-col items-center justify-center">
                <p className="mb-4 text-[#000000]  dark:text-white">
                  <Trans
                    i18nKey="billing.confirmMsg"
                    values={{
                      text:
                        planAction === "downgrade"
                          ? t("billing.Downgrade")
                          : t("billing.Upgrade"),
                    }}
                    components={{
                      strong: <strong />,
                    }}
                  />
                </p>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => submitUpgradePlan()}
                className="dark:bg-blue-700 dark:text-white"
              >
                {t("yes")}
              </Button>
              <Button onClick={handleUpgradeClose}>{t("cancel")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isCentered
          initialFocusRef={initialRef}
          isOpen={isUpgradeDowngradeConfirmModal}
          onClose={handleUpgradeDowngradeClose}
          size={"md"}
          scrollBehavior={"inside"}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent className="dark:bg-navy-900">
            <ModalCloseButton />
            <ModalBody pb={6}>
              <div className="mt-6 flex flex-col items-center justify-center">
                <p className="mb-4 text-[#000000]  dark:text-white">
                  <Trans
                    i18nKey="upgradePlanConfirm"
                    values={{
                      text:
                        planAction === "downgrade"
                          ? t("billing.Downgrade")
                          : t("billing.Upgrade"),
                    }}
                    components={{
                      strong: <strong />,
                    }}
                  />
                </p>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => submitUpgradePlan()}
                className="dark:bg-blue-700 dark:text-white"
              >
                {t("yes")}
              </Button>
              <Button onClick={handleUpgradeDowngradeClose}>
                {t("cancel")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
      <div className="mt-8">
        <Card extra={"w-full h-full p-[16px] bg-cover"}>
          <div className="flex items-center justify-between gap-3">
            <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
              {t("billing.subscriptionPlan")}
            </div>
            {activePlanData && activePlanData?.customer_id && (
              <button
                className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                onClick={() => transactionLogs()}
              >
                {t("transactionLogs")}
              </button>
            )}
          </div>
          <Box p={4} pt={6}>
            <div className="mb-4 flex justify-center">
              <div className="flex items-center space-x-4">
                <span
                  className={`text-md font-medium ${
                    !isYearly
                      ? "text-blue-700"
                      : "text-navy-700 dark:text-white"
                  }`}
                >
                  {t("billing.Monthly")}
                </span>
                <Box
                  as="label"
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  ml="4"
                  mr="4"
                >
                  <Box position="relative">
                    <input
                      type="checkbox"
                      checked={isYearly}
                      onChange={togglePlanType}
                      className="sr-only" // Hide default checkbox
                    />
                    {/* Switch Background */}
                    <Box
                      h="8"
                      w="14"
                      rounded="full"
                      border="1px"
                      borderColor="#BFCEFF"
                      bg="#EAEEFB"
                    />
                    {/* Switch Circle */}
                    <Box
                      className="dot"
                      position="absolute"
                      top="1"
                      left={isYearly ? "calc(100% - 1.7rem)" : "1"}
                      h="6"
                      w="6"
                      rounded="full"
                      bg="#14bfff"
                      transition="left 0.3s ease"
                    />
                  </Box>
                </Box>
                <span
                  className={`text-md font-medium ${
                    isYearly ? "text-blue-700" : "text-navy-700 dark:text-white"
                  }`}
                >
                  {t("billing.Yearly")} (20%)
                </span>
              </div>
            </div>
            {filteredData && filteredData.length > 0 && (
              <table className="w-full">
                <thead className="border-b-2">
                  <tr>
                    <th className="px-4 py-4">
                      <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                        {t("packageName")}
                      </p>
                    </th>{" "}
                    {filteredData.map((item: any, index: number) => (
                      <th key={index} className="px-4 py-4">
                        <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                          {item?.package_name}
                        </p>
                        {activePlanData &&
                          !activePlanData?.cancel_status &&
                          activePlan &&
                          activePlan?.package_id === item?.package_id && (
                            <button
                              onClick={() => setIsCancelConfirmModal(true)}
                              className="mt-2 rounded bg-red-500 px-4 py-[4px] text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-600"
                            >
                              {t("cancel")}
                            </button>
                          )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Row for Rate Per Minute */}
                  {!isYearly ? (
                    <tr>
                      <th className="px-4 py-4">
                        <p className="text-center text-sm font-bold text-navy-700 dark:text-white"></p>
                      </th>
                      {filteredData.map((item: any, index: number) => (
                        <td key={index} className="px-4 py-4">
                          <p className="text-center text-3xl font-bold text-navy-700 dark:text-white">
                            $ {item?.monthly_subscription_fee || 0}
                          </p>
                        </td>
                      ))}
                    </tr>
                  ) : (
                    <tr>
                      <th className="px-4 py-4">
                        <p className="text-center text-sm font-bold text-navy-700 dark:text-white"></p>
                      </th>
                      {filteredData.map((item: any, index: number) => (
                        <td key={index} className="px-4 py-4">
                          <p className="text-center text-3xl font-bold text-navy-700 dark:text-white">
                            $ {item?.yearly_subscription_fee || 0}
                          </p>
                        </td>
                      ))}
                    </tr>
                  )}
                  <tr>
                    <th className="px-4 py-4">
                      <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                        {t("playgroundPMC")}
                        <TooltipHorizon
                          trigger={
                            <span>
                              <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
                            </span>
                          }
                          placement="top"
                          content={t("PlaygroundPerMinute")}
                          extra="rounded text-[11px]"
                        />
                      </p>
                    </th>
                    {filteredData.map((item: any, index: number) => (
                      <td key={index} className="px-4 py-4">
                        <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                          $ {item?.playground_rate || 0}
                        </p>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th className="px-4 py-4">
                      <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                        {t("campaignPMC")}
                        <TooltipHorizon
                          trigger={
                            <span>
                              <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
                            </span>
                          }
                          placement="top"
                          content={t("CampaignPerMinute")}
                          extra="rounded text-[11px]"
                        />
                      </p>
                    </th>
                    {filteredData.map((item: any, index: number) => (
                      <td key={index} className="px-4 py-4">
                        <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                          $ {item?.rate_per_minute || 0}
                        </p>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th className="px-4 py-4">
                      <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                        {t("dailyCallLimit")}
                      </p>
                    </th>
                    {filteredData.map((item: any, index: number) => (
                      <td key={index} className="px-4 py-4">
                        <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                          {item?.daily_minutes} {t("Minutes")}
                        </p>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th className="px-4 py-4">
                      <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white"></p>
                    </th>
                    {filteredData.map((item: any, index: number) => (
                      <td key={index} className="px-4 py-4">
                        {!isYearly ? (
                          <div className="text-center text-sm font-bold text-navy-700 dark:text-white">
                            {activePlan &&
                            activePlan?.package_id !== item?.package_id ? (
                              <>
                                {Number(item?.monthly_subscription_fee) >
                                Number(activePlan?.monthly_subscription_fee) ? (
                                  <button
                                    className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                    onClick={() =>
                                      openUpgradeModal(item, "upgrade")
                                    }
                                  >
                                    {t("billing.Upgrade")}
                                  </button>
                                ) : (
                                  <>
                                    {Number(item?.monthly_subscription_fee) !==
                                      0 && (
                                      <button
                                        className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                        onClick={() =>
                                          openUpgradeModal(item, "downgrade")
                                        }
                                      >
                                        {t("billing.Downgrade")}
                                      </button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : activePlan &&
                              activePlan.package_id === item?.package_id ? (
                              <>
                                <Tag variant="solid" colorScheme="green">
                                  {t("billing.active")}
                                </Tag>
                                {activePlanData?.cancel_status && (
                                  <div className="mt-5 flex items-center justify-center text-center">
                                    <TooltipHorizon
                                      trigger={
                                        <span>
                                          <FaInfoCircle className="h-5 w-5 cursor-pointer text-blue-700" />
                                        </span>
                                      }
                                      placement="top"
                                      content={`${t("cancelPlanInfo")} ${
                                        activePlanData?.next_payment_date
                                      }`}
                                      extra="rounded text-[11px]"
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {!activePlan &&
                                !isYearly &&
                                (item?.monthly_subscription_fee === 0 ||
                                  item?.monthly_subscription_fee === "0") ? (
                                  <Tag variant="solid" colorScheme="green">
                                    {t("billing.active")}
                                  </Tag>
                                ) : (
                                  // <>
                                  //   {activePlan &&
                                  //     !isYearly &&
                                  //     (item?.monthly_subscription_fee !== 0 ||
                                  //       item?.monthly_subscription_fee !==
                                  //         "0") && (
                                  //       <button
                                  //         className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                  //         onClick={() =>
                                  //           openUpgradeModal(item, "subscribe")
                                  //         }
                                  //       >
                                  //         {t("billing.Upgrade")}
                                  //       </button>
                                  //     )}
                                  // </>
                                  <button
                                    className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                    onClick={() =>
                                      openUpgradeModal(item, "subscribe")
                                    }
                                  >
                                    {t("billing.Upgrade")}
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="text-center text-sm font-bold text-navy-700 dark:text-white">
                            {activePlan &&
                            activePlan?.package_id !== item?.package_id ? (
                              <>
                                {Number(item?.yearly_subscription_fee) >
                                Number(activePlan?.yearly_subscription_fee) ? (
                                  <button
                                    className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                    onClick={() =>
                                      openUpgradeModal(item, "upgrade")
                                    }
                                  >
                                    {t("billing.Upgrade")}
                                  </button>
                                ) : (
                                  <button
                                    className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                    onClick={() =>
                                      openUpgradeModal(item, "downgrade")
                                    }
                                  >
                                    {t("billing.Downgrade")}
                                  </button>
                                )}
                              </>
                            ) : activePlan &&
                              activePlan.package_id === item?.package_id ? (
                              <>
                                <Tag variant="solid" colorScheme="green">
                                  {t("billing.active")}
                                </Tag>
                              </>
                            ) : (
                              <>
                                {!activePlan &&
                                !isYearly &&
                                (item?.yearly_subscription_fee === 0 ||
                                  item?.yearly_subscription_fee === "0") ? (
                                  <Tag variant="solid" colorScheme="green">
                                    {t("billing.active")}
                                  </Tag>
                                ) : (
                                  <button
                                    className="rounded bg-blue-700 px-4 py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                                    onClick={() =>
                                      openUpgradeModal(item, "subscribe")
                                    }
                                  >
                                    {t("billing.Upgrade")}
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            )}
            {!loading && filteredData.length === 0 && (
              <div className="text-md text-center font-bold dark:text-white">
                {t("noRecordFound")}
              </div>
            )}
          </Box>
        </Card>
        <Modal
          isCentered
          initialFocusRef={initialRef}
          isOpen={isCancelConfirmModal}
          onClose={handleCancelPlanClose}
          size={"md"}
          scrollBehavior={"inside"}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent className="dark:bg-navy-900">
            <ModalCloseButton />
            <ModalBody pb={6}>
              <div className="mt-6 flex flex-col items-center justify-center">
                <p className="text-md font-bold text-[#000000] dark:text-white">
                  {t("cancelPlanMsg")}
                </p>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => cancelPlan()}
                className="dark:bg-blue-700 dark:text-white"
              >
                {t("yes")}
              </Button>
              <Button onClick={handleCancelPlanClose}>{t("cancel")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default Price;
