/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { SiKnowledgebase } from "react-icons/si";
import {
  RiLockPasswordFill,
  RiMessage2Fill,
  RiMoonFill,
  RiSunFill,
} from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { FaUserCog } from "react-icons/fa";
import { IoDocumentText, IoExtensionPuzzleOutline } from "react-icons/io5";
import EditProfileModal from "components/modal/EditProfileModal";
import FeedBackModal from "components/modal/FeedbackModal";
import { profileDetail } from "services/Auth";
import precallLogo from "../../assets/img/precallAi.png";
import { useColorMode } from "@chakra-ui/system";
import ChangePasswordModal from "components/modal/ChangePasswordModal";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import OnboardingScreenModal from "components/modal/OnboardingScreenModal";
import { HiSwatch } from "react-icons/hi2";
const Navbar = (props: {
  pageRefresh: any;
  setPageRefresh: any;
  handleSidebarToggle: any;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSidebarToggle, brandText, pageRefresh, setPageRefresh } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [onbordingModalOpen, setOnbordingModalOpen] = useState(false);

  const [userDetails, setUserDetails] = useState<any>("");
  const { colorMode, toggleColorMode } = useColorMode();
  const dropdownRef = useRef(null);
  const languages = [
    {
      name: "EN",
      code: "en",
      flag: `${process.env.REACT_APP_URL_FOR_AUDIO}flags/us.svg`,
    },
    {
      name: "FR",
      code: "fr",
      flag: `${process.env.REACT_APP_URL_FOR_AUDIO}flags/fr.svg`,
    },
    {
      name: "DE",
      code: "de",
      flag: `${process.env.REACT_APP_URL_FOR_AUDIO}flags/de.svg`,
    },
    {
      name: "ES",
      code: "es",
      flag: `${process.env.REACT_APP_URL_FOR_AUDIO}flags/es.svg`,
    },
    // Add more languages as needed
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || i18n.language || "en"
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeLanguage = (code: any) => {
    setSelectedLanguage(code);
    setIsOpen(false); // Close the dropdown after selecting a language
    i18n.changeLanguage(code);
    localStorage.setItem("language", code);
    setPageRefresh(!pageRefresh);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseEditProfile = () => {
    setProfileModalOpen(false);
  };
  const handleClosePassword = () => {
    setPasswordModalOpen(false);
  };
  const handleCloseFeedbackModal = () => {
    setFeedbackModalOpen(false);
  };

  useEffect(() => {
    profileDetail()
      .then((response: any) => {
        if (response?.success) {
          setUserDetails(response?.data[0]);
          localStorage?.setItem("amount", response?.data[0]?.amount);
          localStorage?.setItem(
            "affiliation_amount",
            response?.data[0]?.affiliation_amount
          );
          localStorage?.setItem("user_id", response?.data[0]?.id);
          localStorage?.setItem(
            "affiliate_percentage",
            response?.affiliate_per
          );
          localStorage?.setItem("phone", response?.data[0]?.phone);
          localStorage?.setItem("profileImage", response?.data[0]?.profile_pic);
        }
      })
      .catch((error: any) => {
        console.error("Error submitting feedback:", error);
      });
  }, [profileModalOpen]);

  const handleLogOut = () => {
    navigate("/auth/sign-in");
    let themeChange = localStorage.getItem("isThemeChange");
    let themeMode = localStorage.getItem("themeMode");
    localStorage.clear();
    localStorage.setItem("isThemeChange", themeChange);
    localStorage.setItem("themeMode", themeMode);
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Logout",
        buttonName: "Logout",
      });
    }
  };
  useEffect(() => {
    if (colorMode === "dark" || darkmode) {
      document.body.classList.add("dark");
      setDarkmode(true);
    } else {
      document.body.classList.remove("dark");
      setDarkmode(false);
    }
  }, [colorMode]);

  useEffect(() => {
    let isThemeChange = localStorage.getItem("isThemeChange");
    if (
      !isThemeChange &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.body.classList.add("dark");
      setDarkmode(true);
      if (colorMode === "light") {
        toggleColorMode();
      }
    }
  }, []);
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCloseOnbordingModal = () => {
    setOnbordingModalOpen(false);
  };
  return (
    <>
      <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <div className="ml-[6px]">
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link
              to="#"
              className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {brandText}
            </Link>
          </p>
        </div>
        <div className="flex items-center gap-4">
          <div className="relative flex w-full flex-grow items-center justify-around gap-5 rounded-full bg-white px-6 py-3 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none sm:flex-wrap md:flex-grow-0 md:gap-4 xl:gap-4">
            <span
              className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
              onClick={() => handleSidebarToggle()}
            >
              <FiAlignJustify className="h-5 w-5" />
            </span>
            <button
              onClick={() => {
                setOnbordingModalOpen(true);
              }}
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[10px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
            >
              <HiSwatch className="h-5 w-5" />
              <span className="hidden lg:block">
                {t("navbarMenu.watchTutorial")}
              </span>
            </button>
            <button
              onClick={() => {
                navigate("/admin/price");

                if (Array.isArray((window as any).dataLayer)) {
                  (window as any).dataLayer.push({
                    event: "Billing",
                    buttonName: "Billing ",
                  });
                }
              }}
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[10px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
            >
              <AiOutlineDollarCircle className="h-5 w-5" />
              <span className="hidden lg:block">{t("navbarMenu.billing")}</span>
            </button>
            <div className="relative inline-block text-left" ref={dropdownRef}>
              <button
                className="inline-flex items-center rounded border-[1px] border-blue-700 px-[10px] py-[6px]"
                onClick={toggleDropdown}
              >
                <img
                  src={
                    languages.find((lang) => lang.code === selectedLanguage)
                      .flag
                  }
                  alt={selectedLanguage}
                  className="mr-2 h-5 w-5"
                />
                <span className="hidden md:block">
                  {
                    languages.find((lang) => lang.code === selectedLanguage)
                      .name
                  }
                </span>
              </button>

              {isOpen && (
                <div className="absolute right-0 mt-2 w-20 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none dark:divide-blue-700 dark:border-blue-700 dark:bg-navy-900 dark:text-white">
                  {languages.map((lang) => (
                    <div
                      key={lang.code}
                      className="flex cursor-pointer items-center px-2 py-2 hover:bg-gray-100 dark:hover:bg-navy-700"
                      onClick={() => handleChangeLanguage(lang.code)}
                    >
                      <img
                        src={lang.flag}
                        alt={lang.name}
                        className="mr-2 h-5 w-5"
                      />
                      {lang.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className="cursor-pointer text-gray-600"
              onClick={() => {
                localStorage.setItem("isThemeChange", "true");
                if (darkmode) {
                  document.body.classList.remove("dark");
                  setDarkmode(false);
                  localStorage.setItem("themeMode", "light");
                } else {
                  document.body.classList.add("dark");
                  setDarkmode(true);
                  localStorage.setItem("themeMode", "dark");
                }
                toggleColorMode();
              }}
            >
              {colorMode === "dark" ? (
                <RiSunFill
                  size={25}
                  className="text-gray-600 dark:text-white"
                />
              ) : (
                <RiMoonFill
                  size={25}
                  className="text-gray-600 dark:text-white"
                />
              )}
            </div>
            {/* Profile & Dropdown */}
            <Dropdown
              button={
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={
                    userDetails?.profile_pic
                      ? userDetails?.profile_pic
                      : precallLogo
                  }
                  alt="Elon Musk"
                />
              }
              classNames={"py-2 top-8 -left-[180px] w-max"}
            >
              {(closeDropdown) => (
                <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-2 shadow-xl shadow-shadow-500 dark:bg-navy-900 dark:text-white dark:shadow-none">
                  <div className="ml-4 mt-3">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        👋 Hey, {userDetails?.first_name}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                  <div className="ml-4 mt-3 flex flex-col">
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <span
                        className="flex items-center gap-2"
                        onClick={() => {
                          setProfileModalOpen(true);
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: "Profile Settings",
                              buttonName: "Profile Settings ",
                            });
                          }
                        }}
                      >
                        <FaUserCog /> {t("navbarMenu.profileSetting")}
                      </span>
                    </div>
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <div
                        onClick={() => {
                          setPasswordModalOpen(true);
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: " Change Password",
                              buttonName: " Change Password ",
                            });
                          }
                        }}
                        className="flex items-center gap-2"
                      >
                        <RiLockPasswordFill /> {t("navbarMenu.changePassword")}
                      </div>
                    </div>
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <div
                        onClick={() => {
                          navigate("/admin/knowledge-base");
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: "  Knowledge Base",
                              buttonName: " Knowledge Base ",
                            });
                          }
                        }}
                        className="flex items-center gap-2"
                      >
                        <SiKnowledgebase /> {t("navbarMenu.knowledgeBase")}
                      </div>
                    </div>
                    {/* <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                    <div
                      onClick={() => {
                        navigate("/admin/invite-users");

                        if (Array.isArray((window as any).dataLayer)) {
                          (window as any).dataLayer.push({
                            event: "  Invite Users",
                            buttonName: " Invite Users ",
                          });
                        }
                      }}
                      className="flex items-center gap-2"
                    >
                      <FaUserPlus /> Invite Users
                    </div>
                  </div> */}
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <div
                        onClick={() => {
                          navigate("/admin/affiliate");
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: "  Knowledge Base",
                              buttonName: " Knowledge Base ",
                            });
                          }
                        }}
                        className="flex items-center gap-2"
                      >
                        <BsCurrencyDollar /> {t("navbarMenu.affiliates")}
                      </div>
                    </div>
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <div
                        onClick={() => {
                          navigate("/admin/price");
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: "  Billing",
                              buttonName: " Billing ",
                            });
                          }
                        }}
                        className="flex items-center gap-2"
                      >
                        <AiOutlineDollarCircle /> {t("navbarMenu.billing")}
                      </div>
                    </div>
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <div
                        onClick={() => {
                          navigate("/admin/integrations");
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: "Integrations",
                              buttonName: "Integrations ",
                            });
                          }
                        }}
                        className="flex items-center gap-2"
                      >
                        <IoExtensionPuzzleOutline />{" "}
                        {t("navbarMenu.integrations")}
                      </div>
                    </div>
                    <div className="mt-3 w-fit cursor-pointer hover:text-red-500">
                      <span
                        className="flex items-center gap-2"
                        onClick={() => {
                          setFeedbackModalOpen(true);
                          closeDropdown();
                          if (Array.isArray((window as any).dataLayer)) {
                            (window as any).dataLayer.push({
                              event: "Feedback",
                              buttonName: "Feedback ",
                            });
                          }
                        }}
                      >
                        <RiMessage2Fill /> {t("navbarMenu.feedBack")}
                      </span>
                    </div>
                    <div className="mt-3 w-fit hover:text-red-500">
                      <a
                        href="https://docs.precallai.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2"
                      >
                        <IoDocumentText /> {t("navbarMenu.documentation")}
                      </a>
                    </div>
                    <div className="mt-3 w-fit hover:text-red-500">
                      <div
                        onClick={handleLogOut}
                        className="flex cursor-pointer items-center gap-2"
                      >
                        <MdLogout /> {t("navbarMenu.logOut")}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropdown>
          </div>
        </div>
      </nav>
      <EditProfileModal
        onClose={handleCloseEditProfile}
        isOpen={profileModalOpen}
      />
      <ChangePasswordModal
        onClose={handleClosePassword}
        isOpen={passwordModalOpen}
      />
      <FeedBackModal
        onClose={handleCloseFeedbackModal}
        isOpen={feedbackModalOpen}
      />
      <OnboardingScreenModal
        onClose={handleCloseOnbordingModal}
        isOpen={onbordingModalOpen}
      />
    </>
  );
};

export default Navbar;
